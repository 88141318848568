@import "includes.scss";

body {
  margin: 0;
  padding: 0;
  background: $color-bg !important;
}

html, body, td, input, select, h1, h2, h3, h4, h5, h6 {
  font-family: "Open Sans", sans-serif !important;
  color: $color-text;
}

::-webkit-input-placeholder {
  font-weight: 300 !important;
}

.fullContainer {
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  .bodyContainer {
    flex: 1;
    background: $color-bg;
  }
}

.hidden {
  display: none !important;
}

.dimmer .body {
  margin: 0 20% 20px;
  font-size: 12px;
}

@media only screen and (max-width: $largestMobileScreen) {
  .left-mobile-pad {
    padding-left: 1rem !important;
  }

  .both-mobile-pad {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .mobile-hidden {
    display: none !important;
  }

  #root .ui.container {
    width: auto !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

@media only screen and (min-width: $tabletBreakpoint) {
  .mobile-only {
    display: none !important;
  }
}

div#jivo-iframe-container:not(.jivo-c-mobile) {
  z-index: 10 !important;
}

.__jivoMobileButton {
  zoom: 0.6 !important;
}