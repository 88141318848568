@import "../../../../includes.scss";

.r-stop {
  width: 100%;
  text-align: center;
  margin: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .stop-container {
    color: $color-dark;
    min-width: 50%;
    border: 1px dotted rgba(0, 0, 0, 0.2);
    background: rgba(255, 255, 255, 0.4);
    //border-bottom: 1px dotted rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 5px 25px;
    font-size: 12px;
    font-weight: 300;
    strong {
      font-weight: 500;
    }
  }
}