$color-link: #4183c4;
$color-bg-index: #125e81;
$color-black: #000;
$color-dark: #565a5c;
$color-border: #eaeaea;
$color-highlight: #e04f67;
$color-footer-yellow: #fdf7ac;
$color-expander-bg: #F1F9FF;
$color-expander-text: #547998;
$color-critical: #2185d0;
$color-top-line: #71bfe0;
$color-stop-bg: #f5f5f5;
$color-bg: #eeeeee;
$color-success: #47af38;
$color-flights: #bbbebe;
$color-slider: #4f9bcb;

$color-text: $color-black;

$color-button-primary: #f2711c;
$color-button-primary-hover: saturate(darken($color-button-primary, 5%), 10%);
$color-button-primary-down: darken($color-button-primary, 10);
$color-button-primary-shadow: rgba(darken(desaturate($color-button-primary, 50%), 10%), 0.5);

$shadow: 0 1px 3px 0 #d4d4d5, 0 1px 0 0 #d4d4d5;
$border: 1px solid rgba(34, 36, 38, .15);

@mixin card {
  background: #FFF;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 1px 0 0 #d4d4d5;
}

@mixin button {

}

//===============================================================================

$mobileBreakpoint: 320px;
$tabletBreakpoint: 768px;
$computerBreakpoint: 992px;
$largeMonitorBreakpoint: 1200px;
$widescreenMonitorBreakpoint: 1920px;

/* Responsive */
$largestMobileScreen: ($tabletBreakpoint - 1px);
$largestTabletScreen: ($computerBreakpoint - 1px);
$largestSmallMonitor: ($largeMonitorBreakpoint - 1px);
$largestLargeMonitor: ($widescreenMonitorBreakpoint - 1px);